import React from 'react'
import Div from '../private/Div'

const Health = () => {
  return (
    <Div className="page-layout">
      <Div className="sticky-header rounded-t-xl">ok</Div>
    </Div>
  )
}

export default Health
